import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Meta = ({ meta }) => {
  if (!meta) {
    return null;
  }

  return (
    <Helmet>
      {meta.title && <title>{meta.title}</title>}
      {meta.description && (
        <meta name="description" content={meta.description} />
      )}
      {meta.keywords && <meta name="keywords" content={meta.keywords} />}
      {meta.googleSiteVerification && (
        <meta
          name="google-site-verification"
          content={meta.googleSiteVerification}
        />
      )}
    </Helmet>
  );
};

Meta.propTypes = {
  meta: PropTypes.object
};

export default Meta;
