import React from 'react';
import PropTypes from 'prop-types';
import { openPopupWidget, CalendlyEventListener } from 'react-calendly';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import './Button.css';

const Button = ({ className = '', text, calendly }) => {
  const { url, googleAnalytics } = calendly;
  const onClick = () => openPopupWidget({ url });
  // const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
  // const onEventScheduled = () => window.alert('calendly callback fired');
  const onEventScheduled = () => {
    trackCustomEvent(googleAnalytics);
  };
  return (
    <span>
      <CalendlyEventListener
        onDateAndTimeSelected={function noRefCheck() {}}
        onEventScheduled={onEventScheduled}
        onEventTypeViewed={function noRefCheck() {}}
        onProfilePageViewed={function noRefCheck() {}}
      ></CalendlyEventListener>
      <button onClick={onClick} className={`button ${className}`}>
        {text}
      </button>
    </span>
  );
};

Button.propTypes = {
  calendly: PropTypes.object,
  text: PropTypes.string,
  className: PropTypes.string
};

export default Button;
