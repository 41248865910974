import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import Link from 'gatsby-link';

import Logo from '~theme/images/logo.svg';
import './MobileNav.css';

// eslint-disable-next-line
const MobileNav = () => (
  <nav className="mobile-nav">
    <Menu right isOpen={false} width="100%">
      <img src={Logo} className="mobile-nav-logo" />

      <ul className="mobile-nav-list">
        <li className="mobile-nav-item">
          <Link to="/" className="mobile-nav-link" title="Home">
            Home
          </Link>
        </li>

        <li className="mobile-nav-item">
          <Link to="/kubeflow" className="mobile-nav-link" title="Kubeflow">
            Kubeflow Service
          </Link>
        </li>

        <li className="mobile-nav-item">
          <Link to="/blog" className="mobile-nav-link" title="Blog">
            Blog
          </Link>
        </li>

        <li className="mobile-nav-item">
          <Link to="/contact" className="mobile-nav-link" title="Contact">
            Contact
          </Link>
        </li>
      </ul>
    </Menu>
  </nav>
);

export default MobileNav;
