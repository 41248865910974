import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import './Button.css';

const Button = ({ className = '', text, linkURL = '' }) => (
  <Link className={`button ${className}`} to={linkURL}>
    {text}
  </Link>
);

Button.propTypes = {
  linkURL: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};

export default Button;
