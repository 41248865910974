import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import Nav from '~/components/Nav/Nav';
import MobileNav from '~/components/MobileNav/MobileNav';
import Container from '~/components/Container/Container';
import Hero from '~/components/Hero/Hero';
import HeroProduct from '~/components/HeroProduct/HeroProduct';

import Logo from '~theme/images/logo.svg';
import './Header.css';

const Header = ({ isHome, page, calendly, googleAnalytics }) => (
  <header className={`header ${isHome ? 'home' : ''}`}>
    <Container>
      <div className="header-wrapper">
        <Link to="/">
          <img src={Logo} className="header-logo" />
        </Link>
        <Nav calendly={calendly} />
        <MobileNav />
      </div>

      {isHome && <Hero />}
      {page == 'kubeflow' && (
        <HeroProduct calendly={calendly} googleAnalytics={googleAnalytics} />
      )}
    </Container>
  </header>
);

Header.propTypes = {
  calendly: PropTypes.object,
  googleAnalytics: PropTypes.object,
  isHome: PropTypes.bool,
  page: PropTypes.string
};

export default Header;
