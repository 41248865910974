import React from 'react';
import PropTypes from 'prop-types';

import ButtonMailto from '~/components/Button/ButtonMailto.jsx';
import ButtonCalendly from '~/components/Button/ButtonCalendly.jsx';

import './HeroProduct.css';

const Hero = ({ calendly, googleAnalytics }) => {
  return (
    <div className="heroProduct">
      <div className="heroProduct-text">
        <div className="product-pre">Introducing</div>
        <h1 className="heroProduct-h1">XLR8R's Managed Kubeflow Service</h1>
        <h2 className="heroProduct-subTitle">
          {/*Get a best-in-class kubeflow deployment in minutes not months.*/}
          One click production-ready deployments of Kubeflow.
        </h2>
        {/*				<h2 className="heroProduct-h2">
					Fully Hosted or Deployed to your own Cloud
				</h2>
				<h3 className="heroProduct-h3">
					Flexible deployment options available to meet your requirements.
				</h3>*/}

        <div className="heroProduct-ctas">
          <ButtonMailto
            googleAnalytics={googleAnalytics && googleAnalytics.kubeflowInquiry}
            className="heroProduct-button heroProduct-enquire"
            recipient="inquiries@cloudxlr8r.com"
            subject="XLR8R Kubeflow Service Inquiry"
            body="I'd like to discuss onboarding my company to XLR8R's Kubeflow Service."
            text="Request Access"
          />
          <ButtonCalendly
            className="heroProduct-button heroProduct-demo"
            text="Schedule Demo"
            calendly={calendly.kubeflow}
          />
        </div>
      </div>
      <div className="heroProduct-img"></div>
      <div className="cl"></div>
    </div>
  );
};

Hero.propTypes = {
  googleAnalytics: PropTypes.object,
  calendly: PropTypes.object
};

export default Hero;
