import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';

import Button from '~/components/Button/Button.jsx';
import ButtonCalendly from '~/components/Button/ButtonCalendly.jsx';

import './Nav.css';

const Nav = ({ calendly }) => (
  <nav className="nav">
    <ul className="nav-list">
      <li className="nav-item">
        <Link to="/kubeflow" className="nav-link" title="Kubeflow">
          Kubeflow Service
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/blog" className="nav-link" title="Blog">
          Blog
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/contact" className="nav-link" title="Contact">
          Contact
        </Link>
      </li>
    </ul>

    <ButtonCalendly
      text="Request Demo"
      className="demo"
      calendly={calendly.kubeflow}
    />
    <Button
      text="Docs"
      className="docs"
      linkURL="https://docs.cloudxlr8r.com/"
    />
  </nav>
);

Nav.propTypes = {
  calendly: PropTypes.object
};

export default Nav;
