import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Meta from '~/components/Meta/Meta';
import Container from '~/components/Container/Container';
import Header from '~/components/Header/Header';
import Footer from '~/components/Footer/Footer';

import '~theme/theme.css';
import './Reset.css';
import './Layout.css';

const Layout = ({
  children,
  meta,
  socials,
  hasContainer,
  title,
  isHome,
  googleAnalytics,
  calendly,
  page
}) => (
  <div className="layout">
    <Meta meta={meta} />

    <Header
      googleAnalytics={googleAnalytics}
      isHome={isHome}
      isProduct={true}
      page={page}
      calendly={calendly}
    />

    {hasContainer && <Container>{children}</Container>}

    {!hasContainer && <Fragment>{children}</Fragment>}

    <Footer socials={socials} title={title} />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  meta: PropTypes.object,
  googleAnalytics: PropTypes.object,
  socials: PropTypes.object,
  calendly: PropTypes.object,
  hasContainer: PropTypes.bool,
  page: PropTypes.string,
  isHome: PropTypes.bool
};

Layout.defaultProps = {
  hasContainer: true,
  isHome: false
};

export default Layout;
