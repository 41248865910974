import React from 'react';
import PropTypes from 'prop-types';

import './Button.css';

import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const ButtonMailto = ({
  text,
  className = '',
  recipient,
  subject,
  body,
  googleAnalytics
}) => {
  const onClick = () => trackCustomEvent(googleAnalytics);
  // const onClick = () => {
  // 	if (googleAnalytics) {
  // 		alert(JSON.stringify(googleAnalytics, null, 2));
  // 	}
  // }
  return (
    <a
      className={`button ${className}`}
      onClick={onClick}
      href={`mailto:${recipient}?subject=${subject}&body=${body}`}
    >
      {text}
    </a>
  );
};

ButtonMailto.propTypes = {
  googleAnalytics: PropTypes.object,
  recipient: PropTypes.string,
  replyto: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};

export default ButtonMailto;
