import React from 'react';
// import SignupButton from '~/components/SignupButton/SignupButton';
// import Link from 'gatsby-link';
import HeroImage from '~theme/images/hero.svg';
import HashicorpLogo from '~theme/images/vendors-logos/hashicorp.svg';
import AzureLogo from '~theme/images/vendors-logos/azure.svg';
import AwsLogo from '~theme/images/vendors-logos/aws.svg';
import CircleCiLogo from '~theme/images/vendors-logos/circleci.svg';
import GoogleCloudLogo from '~theme/images/vendors-logos/google.svg';

import './Hero.css';

const Hero = () => (
  <div className="hero">
    <div className="hero-content">
      <h1 className="hero-title">
        MLOps Consultancy delivering best-in-class infrastructure and pipelines
        {/*MLOps Consultancy delivering best-in-class ML platforms and repeatable pipelines that enables companies to deploy models to production quickly and with confidence.*/}
        {/*MLOps Consultancy delivering best-in-class ML platforms and repeatable pipelines to help companies get from experiment to deployment .*/}
        {/*Production ready MLOps in minutes not months*/}
      </h1>

      <p className="hero-text">
        Cloud XLR8R deliver scalable ML platforms, repeatable ML & CT pipelines
        and CI/CD deployment for any cloud or on premise.
        {/*Focus on data science not devops with our managed <Link to="/kubeflow" className="nav-item" title="Kubeflow">Kubeflow Service</Link>.*/}
        {/*Increase your experimentation velocity and deploy advanced models ahead of the competition.*/}
        {/*Increase your experimentation velocity and deploy advanced models ahead of the competition.*/}
      </p>
      {/*      <h2>
        Focus on data science not devops with our managed{' '}
        <Link to="/kubeflow" className="nav-item" title="Kubeflow">
          Kubeflow Service
        </Link>
        . Get a production ready kubeflow deployment in minutes not months.
      </h2>*/}

      <div className="hero-logos">
        <img src={HashicorpLogo} alt="HashiCorp" width="60" height="30" />
        <img src={AzureLogo} alt="Azure" width="60" height="30" />
        <img src={AwsLogo} alt="AWS" width="30" height="30" />
        <img src={CircleCiLogo} alt="CircleCi" width="60" height="30" />
        <img src={GoogleCloudLogo} alt="Google Cloud" width="90" height="30" />
      </div>

      {/*<SignupButton className="hero-signup-button" />*/}
    </div>

    <div className="hero-image">
      <img src={HeroImage} />
    </div>
  </div>
);

export default Hero;
