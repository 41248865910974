import React from 'react';
import PropTypes from 'prop-types';
import Container from '~/components/Container/Container';
import Link from 'gatsby-link';

import './Footer.css';
import Feed from '~/images/feed.svg';
import Twitter from '~/images/twitter.svg';
import Github from '~/images/github.svg';
import Linkedin from '~/images/linkedin.svg';
import CyberEssential from '~/images/cyberessential.svg';
import Logo from '~theme/images/logo.svg';

const Footer = ({ socials, title }) => (
  <footer className="footer">
    <Container>
      <img src={Logo} className="footer-logo" />

      <ul className="footer-nav-list">
        <li className="footer-nav-item">
          <Link to="/kubeflow" className="footer-nav-link" title="Kubeflow">
            Kubeflow Service
          </Link>
        </li>

        <li className="footer-nav-item">
          <Link
            to="https://docs.cloudxlr8r.com/"
            className="footer-nav-link"
            title="Kubeflow Service Docs"
          >
            Docs
          </Link>
        </li>

        <li className="footer-nav-item">
          <Link to="/blog" className="footer-nav-link" title="Blog">
            Blog
          </Link>
        </li>

        <li className="footer-nav-item">
          <Link to="/contact" className="footer-nav-link" title="Contact">
            Contact
          </Link>
        </li>
      </ul>

      <div className="footer-content">
        <span>
          &copy; {new Date().getFullYear()} {title} Ltd
        </span>

        <ul className="footer-socials">
          {socials.feed && (
            <li className="footer-socials-item">
              <a href={socials.feed} title="RSS">
                <img src={Feed} />
              </a>
            </li>
          )}

          {socials.twitter && (
            <li className="footer-socials-item">
              <a href={socials.twitter} title="Twitter">
                <img src={Twitter} />
              </a>
            </li>
          )}

          {socials.github && (
            <li className="footer-socials-item">
              <a href={socials.github} title="Github">
                <img src={Github} />
              </a>
            </li>
          )}

          {socials.linkedin && (
            <li className="footer-socials-item">
              <a href={socials.linkedin} title="LinkedIn">
                <img src={Linkedin} />
              </a>
            </li>
          )}
        </ul>

        {socials.email && (
          <a href={`mailto:${socials.email}`}>{socials.email}</a>
        )}

        <img src={CyberEssential} className="footer-cyberessential" />
      </div>
    </Container>
  </footer>
);

Footer.propTypes = {
  title: PropTypes.string.isRequired,
  socials: PropTypes.object
};

export default Footer;
